import { PostCompany, GetCompanies } from "@/server-types";
import { post, get } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

export const usePostCompany = () => {
  return useCustomMutation({
    mutationFn: (body: PostCompany["body"]) =>
      post<PostCompany>("/company", body),
  });
};

export const useGetCompanies = (query: GetCompanies["query"]) => {
  return useCustomQuery({
    queryKey: ["companies", query],
    queryFn: () => get<GetCompanies>("/company", query),
    enabled: query.companyId != null || !!query.searchQuery,
  });
};
