import EmptyState from "@/Components/EmptyState";
import { Trans, t } from "@lingui/macro";
import { RiEditBoxLine, RiNotification3Fill } from "react-icons/ri";
import classes from "./NotificationSettings.module.css";

import { ActionIcon, Box, Button, Flex, Switch, Text } from "@mantine/core";
import { IoMdAddCircleOutline } from "react-icons/io";
import { TiTrash } from "react-icons/ti";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import useFeature from "@/hooks/useFeature";
import { useDeleteMessageTemplateById } from "@/lib/api-client/paths/message";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import type { GetNotificationSettings } from "../../../../../server/src/types";
import { NotificationSettingModal } from "./components/NotificationSettingModal";
import { TemplatesTab } from "./components/templatesTab/TemplatesTab";
import { TRIGGERS } from "./constants/Triggers";
import { useNotificationSettings } from "./hooks/useNotificationSettings";
import { minutesToValueAndUnit } from "./utils";

export const NotificationSettings = () => {
  const { mutateAsync: deleteMessageTemplate } = useDeleteMessageTemplateById();

  const { data, openDeleteAlertModal, billingData } = useNotificationSettings();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { alertModal } = useLimeAlertModal();

  const activeTab = pathname.split("/")[3];
  const subPath = pathname.split("/")[4];

  const { isFeatureEnabled } = useFeature();

  if (!activeTab) {
    navigate("/dashboard/notifications/settings");
  }

  const tabs: LimePageTabs["tabs"] = [
    {
      label: t`Obvestila`,
      value: "settings",
    },
  ];

  if (isFeatureEnabled("MESSAGE_SCHEDULE")) {
    tabs.push({
      label: t`Sporočilne predloge`,
      value: "templates",
    });
  }

  const header = (() => {
    if (activeTab === "settings") {
      return <LimePageHeader title={t`Obvestila`}></LimePageHeader>;
    }

    if (activeTab === "templates") {
      if (subPath === undefined) {
        return (
          <LimePageHeader
            title={t`Sporočilne predloge`}
            rightSection={{
              label: t`Dodaj novo predlogo`,
              onClick: () =>
                navigate("/dashboard/notifications/templates/create"),
            }}
          ></LimePageHeader>
        );
      }
      return (
        <LimePageHeader
          title={""}
          subPage={{
            title:
              subPath === "create"
                ? t`Ustvarjanje predloge`
                : `Urejanje predloge`,
            onBackButtonClick: () =>
              navigate("/dashboard/notifications/templates"),
            rightSection: {
              options: [
                {
                  label: "Izbriši predlogo",
                  onClick: () => {
                    alertModal({
                      onConfirm: async () => {
                        await deleteMessageTemplate({
                          id: Number(subPath),
                        })
                          .then(() => {
                            notifications.show({
                              message: t`Sporočilo izbrisano`,
                              color: "green",
                            });
                            navigate("/dashboard/notifications/templates");
                          })
                          .catch((error) => {
                            notifications.show({
                              title: t`Napaka`,
                              message: error.message,
                              color: "red",
                            });
                          });
                      },
                      title: t`Izbriši predlogo`,
                    });
                  },
                  color: Color.Error,
                },
              ],
            },
          }}
        ></LimePageHeader>
      );
    }
  })();

  return (
    <>
      {header}
      <LimePageTabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={(tab) => {
          if (!tab) return;

          navigate(`/dashboard/notifications/${tab}`);
        }}
        rightSection={<></>}
      ></LimePageTabs>
      <NotificationSettingModal
        opened={
          location.pathname.includes("/new") ||
          location.pathname.includes("/edit")
        }
        onClose={() => navigate("/dashboard/notifications/settings")}
      ></NotificationSettingModal>
      <Routes>
        <Route
          path="settings/*"
          element={
            <>
              <Box className={classes.wrapper} px={isMobile ? undefined : "xl"}>
                {data ? (
                  <>
                    {TRIGGERS().map(({ label, trigger }) => (
                      <NotificationGroup
                        key={label}
                        title={label}
                        notifications={data.settings.filter(
                          (s) => s.trigger === trigger,
                        )}
                        openDeleteAlertModal={openDeleteAlertModal}
                        trigger={trigger}
                      ></NotificationGroup>
                    ))}
                  </>
                ) : (
                  <EmptyState
                    wrapperStyle={{ marginTop: "2rem" }}
                    icon={
                      <RiNotification3Fill fill={"#6c7b86"} size={"2rem"} />
                    }
                    title={t`Nimate nastavitev obvestil.`}
                    description={t`Dodajte prvo nastavitev za obveščanje strank.`}
                  />
                )}
              </Box>
              <Box
                className={classes.billingCycleInfo}
                pos={"fixed"}
                bottom={0}
                right={0}
                bg={"white"}
                pl={30}
                pt={5}
                pr={30}
                style={{
                  border: "1px solid #cacaca",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  // borderRight: "none",
                  borderBottom: "none",
                }}
              >
                <Flex direction={"column"} align={"end"}>
                  <Text c={Color.PrimaryText} variant={TextVariant.Subheading}>
                    {billingData?.current.sentSms} /{" "}
                    {billingData?.current.totalSms}
                  </Text>
                  <Text
                    c={Color.SecondaryText}
                    variant={TextVariant.BodyEmphasized}
                  >
                    <Trans>
                      Obdobje od {billingData?.current.startDate} do{" "}
                      {billingData?.current.endDate}
                    </Trans>
                  </Text>
                </Flex>
              </Box>
            </>
          }
        ></Route>
        <Route
          path="templates/*"
          element={
            <Box px={isMobile ? undefined : "xl"}>
              <TemplatesTab></TemplatesTab>
            </Box>
          }
        ></Route>
      </Routes>
    </>
  );
};

const NotificationGroup = ({
  title,
  notifications,
  openDeleteAlertModal,
  trigger,
}: {
  title: string;
  notifications: GetNotificationSettings["response"]["settings"];
  openDeleteAlertModal: (id: number) => void;
  trigger: GetNotificationSettings["response"]["triggers"][0];
}) => {
  const navigate = useNavigate();

  const { handleToggleNotificationSetting } = useNotificationSettings();

  return (
    <div className={classes.groupWrapper}>
      <span className={classes.groupTitle}>{title}</span>
      {notifications.length > 0 ? (
        <div className={classes.groupItems}>
          {notifications.map((notification, i) => (
            <div className={classes.item} key={i}>
              <Flex align={"center"} justify={"space-between"}>
                <span className={classes.pill}>
                  {notification.notification_type}
                </span>
                {notification.trigger === "cronBeforeAppointment" ||
                notification.trigger === "cronAfterAppointment" ? (
                  `${minutesToValueAndUnit(notification.time_offset)?.value} 
                 ${
                   minutesToValueAndUnit(notification.time_offset)?.unitLabel
                 } ${
                   notification.trigger === "cronBeforeAppointment"
                     ? "pred"
                     : "po"
                 }`
                ) : (
                  <></>
                )}
                <Switch
                  checked={notification.enabled}
                  onClick={() =>
                    handleToggleNotificationSetting(
                      notification.notificationSettingId,
                    )
                  }
                ></Switch>
              </Flex>

              <Flex direction={"column"}>
                <span className={classes.userTitle}>
                  <Trans>Storitve</Trans>:
                </span>
                <span className={classes.text}>
                  {notification.all_services
                    ? `vse`
                    : `${notification.notification_setting_service
                        .filter((s, i) => s && i < 2)
                        .map((s) => s.service.name)
                        .join(", ")} ${
                        notification.notification_setting_service.length > 2
                          ? `+ ${
                              notification.notification_setting_service.length -
                              2
                            } ${t`ostale`}`
                          : ""
                      }`}
                </span>
              </Flex>

              {notification.notification_type === "EMAIL" && (
                <Flex direction={"column"}>
                  <span className={classes.userTitle}>
                    <Trans>Zadeva</Trans>:
                  </span>
                  <span className={classes.text}>
                    {notification.notification_subject}
                  </span>
                </Flex>
              )}
              <Flex direction={"column"}>
                <span className={classes.messageTitle}>
                  <Trans>Sporočilo</Trans>:
                </span>
                <span className={classes.text}>
                  {notification.notification_template}
                </span>
              </Flex>

              <div className={classes.buttons}>
                <Button
                  onClick={() =>
                    openDeleteAlertModal(notification.notificationSettingId)
                  }
                  leftSection={<TiTrash size="24px"></TiTrash>}
                  variant="transparent"
                  className={classes.deleteButton}
                  c="rgb(108, 123, 134)"
                  p={"6px 10px"}
                >
                  <Trans>Izbriši</Trans>
                </Button>
                <Button
                  leftSection={<RiEditBoxLine size="18px"></RiEditBoxLine>}
                  p={"6px 10px"}
                  radius={20}
                  onClick={() =>
                    navigate(
                      `/dashboard/notifications/settings/edit?id=${notification.notificationSettingId}`,
                    )
                  }
                >
                  <Trans>Uredi</Trans>
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      <ActionIcon
        onClick={() =>
          navigate(`/dashboard/notifications/settings/new?defaultTrigger=${trigger}`)
        }
        id={`add-notification-${trigger}`}
      >
        <IoMdAddCircleOutline
          size="24px"
          color={"#61906f"}
        ></IoMdAddCircleOutline>
      </ActionIcon>
    </div>
  );
};
