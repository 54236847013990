import { Button, Divider } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { cn } from "@/utils";
import { GetProducts } from "@/server-types";
import { Trans } from "@lingui/macro";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import useFeature from "@/hooks/useFeature";

export const DesktopCategoryServices = ({
  categories,
  services,
  products,
  selectedServiceIds,
  selectedProductIds,
  handleClose,
  search,
  setSearch,
  onGiftCardsClick,
}: {
  categories?: {
    tagId: number;
    localizedName: string;
    color: string | null;
    type: "service" | "product";
  }[];
  services?: {
    serviceId: number;
    color: string | null;
    name: string;
    priceWithTaxFormatted: string;
    service_tag: {
      tag: {
        tagId: number;
        color: string | null;
      };
    }[];
  }[];
  products?: GetProducts["response"]["products"];
  selectedServiceIds: number[];
  selectedProductIds: number[];
  handleClose: (itemId?: number, itemType?: string) => void;
  search: string;
  setSearch: (value: string) => void;
  onGiftCardsClick: () => void;
}) => {
  const { isFeatureEnabled } = useFeature();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const [selectedCategoryType, setSelectedCategoryType] = useState<
    "all" | "service" | "product" | null
  >("all");

  useEffect(() => {
    if (selectedCategoryType !== null || selectedCategoryId !== null) return;

    const firstCategory = categories?.[0];
    if (firstCategory) {
      setSelectedCategoryId(firstCategory.tagId);
      setSelectedCategoryType(firstCategory.type);
    }
  }, [categories]);

  const selectedCategoryServices =
    (selectedCategoryId === -1
      ? services?.filter((service) => service.service_tag.length === 0)
      : services?.filter(
          (service) => service.service_tag[0]?.tag.tagId === selectedCategoryId,
        )
    )?.map(
      (service) =>
        ({
          type: "service",
          id: service.serviceId,
          name: service.name,
          color: service.color,
          priceWithTaxFormatted: service.priceWithTaxFormatted,
          categoryId: service.service_tag[0]?.tag.tagId,
        }) as const,
    ) || [];

  const selectedCategoryProducts =
    (selectedCategoryId === -1
      ? products?.filter((product) => product.categoryId == null)
      : products?.filter((product) => product.categoryId === selectedCategoryId)
    )?.map(
      (product) =>
        ({
          type: "product",
          id: product.id,
          name: product.name,
          color: product.color,
          priceWithTaxFormatted: product.salesProductData?.grossPriceFormatted,
          categoryId: product.categoryId,
          stockQuantity: product.salesProductData?.stockQuantity,
        }) as const,
    ) || [];

  const items = (() => {
    if (selectedCategoryType === "all") {
      if (selectedCategoryId === -1) {
        return [...selectedCategoryServices, ...selectedCategoryProducts];
      }

      const mappedServices = services?.map(
        (service) =>
          ({
            type: "service",
            id: service.serviceId,
            name: service.name,
            color: service.color,
            priceWithTaxFormatted: service.priceWithTaxFormatted,
            categoryId: service.service_tag[0]?.tag.tagId,
          }) as const,
      );
      const mappedProducts = products?.map(
        (product) =>
          ({
            type: "product",
            id: product.id,
            name: product.name,
            color: product.color,
            priceWithTaxFormatted:
              product.salesProductData?.grossPriceFormatted,
            categoryId: product.categoryId,
            stockQuantity: product.salesProductData?.stockQuantity,
          }) as const,
      );

      return [...(mappedServices || []), ...(mappedProducts || [])];
    } else if (selectedCategoryType === "service") {
      return selectedCategoryServices;
    } else if (selectedCategoryType === "product") {
      return selectedCategoryProducts;
    }
  })();

  return (
    <>
      <LimeInput
        label={<Trans>Iskanje</Trans>}
        className="mb-4"
        onValueChange={setSearch}
        value={search}
      />

      {!search ? (
        <div className="grid grid-cols-6 gap-2">
          <Button
            className={cn("w-full", {
              "ring-2 ring-black": !selectedCategoryId,
            })}
            onPress={() => {
              setSelectedCategoryType("all");
              setSelectedCategoryId(null);
            }}
            style={{
              backgroundColor: undefined,
            }}
          >
            <Trans>Vsi artikli</Trans>
          </Button>
          {isFeatureEnabled("GIFTCARD") ? (
            <Button
              onPress={onGiftCardsClick}
              style={{
                backgroundColor: undefined,
                textWrap: "wrap",
              }}
              className="leading-3"
            >
              <Trans>Ustvari darilni bon</Trans>
            </Button>
          ) : undefined}
          {categories?.map((category) => (
            <Button
              key={`${category.type}-${category.tagId}`}
              className={cn("w-full leading-3", {
                "ring-2 ring-black":
                  selectedCategoryId === category.tagId &&
                  selectedCategoryType === category.type,
              })}
              onPress={() => {
                setSelectedCategoryType(category.type);
                setSelectedCategoryId(category.tagId);
              }}
              style={{
                backgroundColor: category.color || undefined,
                textWrap: "wrap",
              }}
            >
              {category.localizedName}
            </Button>
          ))}
        </div>
      ) : undefined}

      <Divider className="my-4" />

      <div className="grid grid-cols-3 gap-1 px-1">
        {items?.map((item) => {
          const isSelected = (() => {
            if (item.type === "service") {
              return selectedServiceIds.includes(item.id);
            }

            if (item.type === "product") {
              return selectedProductIds.includes(item.id);
            }

            return false;
          })();

          const category = categories?.find(
            (category) =>
              category.tagId == item.categoryId && category.type === item.type,
          );

          return (
            <Button
              key={`${item.type}-${item.id}`}
              className="relative min-h-16 items-baseline justify-start px-2 py-2 text-center"
              onPress={() => handleClose(item.id, item.type)}
              style={{
                backgroundColor:
                  (item.type === "service" ? item.color : undefined) ||
                  category?.color ||
                  undefined,
              }}
              isDisabled={isSelected}
            >
              <p className="w-full">
                {item.type === "product" && item.stockQuantity != null ? (
                  <span className="mr-1 text-xs">
                    {item.type === "product" && `(${item.stockQuantity}x)`}
                  </span>
                ) : undefined}
                {item.name}
              </p>

              <p className="absolute bottom-1 right-1 text-xs font-normal">
                {item.priceWithTaxFormatted}
              </p>
            </Button>
          );
        })}
      </div>
    </>
  );
};
