import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import { Button, Divider, Spinner } from "@nextui-org/react";
import { useEffect } from "react";

type Form = {
  skipReauthorizationOnInvoiceCreation: boolean;
  skipAuthorizationGlobally: boolean;
};

export const TaxRegTab = ({ clientId }: { clientId: number }) => {
  const {
    data: taxRegSettings,
    isFetching: isFetchingTaxRegSettings,
    processedErrorMessage: getTaxRegSettingsErrorMessage,
    refetch: refetchTaxRegSettings,
  } = api.taxRegister.useGetOrganizationTaxRegisterSettings({
    clientId,
  });

  const {
    mutateAsync: postTaxRegSettings,
    isPending: isPostingTaxRegSettings,
    processedErrorMessage: postTaxRegSettingsErrorMessage,
  } = api.taxRegister.usePostOrganizationTaxRegisterSettings();

  const form = useForm<Form>({
    initialValues: {
      skipReauthorizationOnInvoiceCreation:
        taxRegSettings?.skipReauthorizationOnInvoiceCreation || false,
      skipAuthorizationGlobally:
        taxRegSettings?.skipAuthorizationGlobally || false,
    },
  });

  useEffect(() => {
    if (!taxRegSettings) return;

    form.initialize({
      skipReauthorizationOnInvoiceCreation:
        taxRegSettings.skipReauthorizationOnInvoiceCreation || false,
      skipAuthorizationGlobally:
        taxRegSettings.skipAuthorizationGlobally || false,
    });
  }, [taxRegSettings]);

  const handleSubmit = async (values: Form) => {
    await postTaxRegSettings({
      ...values,
      clientId,
    });

    await refetchTaxRegSettings();
  };

  if (isFetchingTaxRegSettings) return <Spinner />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <ErrorCard message={getTaxRegSettingsErrorMessage} className="mb-2" />

      <LimeSwitch
        className="mt-2"
        title={"Skip reauthorization on invoice creation"}
        {...form.getInputProps("skipReauthorizationOnInvoiceCreation", {
          type: "checkbox",
        })}
        isDisabled={isFetchingTaxRegSettings}
      />

      <Divider className="my-2" />

      <LimeSwitch
        title={"Skip authorization on all POS screens"}
        {...form.getInputProps("skipAuthorizationGlobally", {
          type: "checkbox",
        })}
        isDisabled={isFetchingTaxRegSettings}
      />

      <Divider className="my-2" />

      <Button
        color="primary"
        type="submit"
        isLoading={isPostingTaxRegSettings}
        isDisabled={isFetchingTaxRegSettings}
      >
        Shrani
      </Button>

      <ErrorCard message={postTaxRegSettingsErrorMessage} className="mt-2" />
    </form>
  );
};
