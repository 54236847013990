import {
  LanguageSettingDrawer,
  LocalizedArray,
} from "@/Components/LanguageSettingsDrawer";
import { LimeColorPicker } from "@/Components/LimeColorPicker";
import {
  LimePageHeader,
  LimePageHeaderProps,
} from "@/Components/LimePageHeader";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeTextarea } from "@/Components/NextBase/LimeTextarea";
import { api } from "@/lib/api-client";
import usePreferredLanguageStore, {
  SUPPORTED_LANGUAGES,
} from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { cn } from "@/utils";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  Collapse,
  Drawer,
  Group,
  Radio,
  Stack,
  Text,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { Button, Divider, Spinner, Tab, Tabs } from "@nextui-org/react";
import { Languages, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { returnCurrencySymbol } from "../../../../../shared/utils/utils";

const baseQuery = z.object({
  categoryId: z.number().nullable(),
  nameLocalized: z.array(
    z.object({
      language: z.enum(SUPPORTED_LANGUAGES),
      value: z.string(),
    }),
  ),
  descriptionLocalized: z.array(
    z.object({
      language: z.enum(SUPPORTED_LANGUAGES),
      value: z.string(),
    }),
  ),
  unit: z.string(),
  isQuantityManaged: z.boolean(),
  SKU: z.string().optional(),
  EAN: z.string().optional(),
  type: z.enum(["PURCHASE", "SALES", "ALL"]),
});

const validationSchema = baseQuery.extend({
  type: z.enum(["ALL", "PURCHASE", "SALES"]),
  salesNetPrice: z.number(),
  salesGrossPrice: z.number(),
  salesTax: z.number(),
  purchaseNetPrice: z.number(),
  purchaseGrossPrice: z.number(),
  purchaseTax: z.number(),
});

export const ManageProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { alertModal } = useLimeAlertModal();

  // APIS
  const {
    isPending: isPostProductLoading,
    mutateAsync: postProduct,
    processedErrorMessage: postErrorMessage,
  } = api.product.usePostProduct();

  const { mutateAsync: putProduct, processedErrorMessage: putErrorMessage } =
    api.product.usePutProduct();

  const { data: existingProductData, isLoadingError: isGetProductError } =
    api.product.useGetProductById(Number(productId));

  const { mutateAsync: deleteProduct } = api.product.useDeleteProductById();

  const { data: currency } = api.values.useGetCurrency({});
  //

  const [
    nameLocalizationDrawerOpened,
    { open: openNameLocalizationDrawer, close: closeNameLocalizationDrawer },
  ] = useDisclosure(false);
  const [
    descriptionLocalizationDrawerOpened,
    {
      open: openDescriptionLocalizationDrawer,
      close: closeDescriptionLocalizationDrawer,
    },
  ] = useDisclosure(false);

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const form = useForm<z.infer<typeof validationSchema>>({
    initialValues: {
      nameLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      descriptionLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      unit: "",
      categoryId: null,
      type: "ALL",
      salesTax: 0,
      salesGrossPrice: 0,
      salesNetPrice: 0,
      purchaseTax: 0,
      purchaseGrossPrice: 0,
      purchaseNetPrice: 0,
      isQuantityManaged: false,
    },
    validate: zodResolver(validationSchema),
  });

  useEffect(() => {
    if (existingProductData) {
      const product = existingProductData.product;

      const type = (() => {
        if (product.PurchaseProductData && product.SalesProductData)
          return "ALL";
        if (product.PurchaseProductData) return "PURCHASE";
        return "SALES";
      })();

      const salesNetPrice =
        (product.SalesProductData?.netPriceCents || 0) / 100;
      const purchaseNetPrice =
        (product.PurchaseProductData?.netPriceCents || 0) / 100;
      const salesTax = product.SalesProductData
        ? Number(product.SalesProductData.taxPercentage)
        : 0;
      const purchaseTax = product.PurchaseProductData
        ? Number(product.PurchaseProductData.taxPercentage)
        : 0;
      const salesGrossPrice =
        (product.SalesProductData?.grossPriceCents || 0) / 100;
      const purchaseGrossPrice =
        (product.PurchaseProductData?.grossPriceCents || 0) / 100;

      form.initialize({
        categoryId: product.categoryId,
        nameLocalized: product.nameLocalized.map((name) => ({
          language: name.language,
          value: name.name,
        })),
        descriptionLocalized: product.descriptionLocalized.map(
          (description) => ({
            language: description.language,
            value: description.description,
          }),
        ),
        unit: product.unit,
        type,
        salesTax,
        salesGrossPrice,
        salesNetPrice,
        isQuantityManaged: product.isQuantityManaged,
        SKU: product.SKU || undefined,
        EAN: product.EAN || undefined,
        purchaseTax,
        purchaseGrossPrice,
        purchaseNetPrice,
      });
    }
  }, [existingProductData]);

  useEffect(() => {
    if (isGetProductError) {
      navigate("/dashboard/products/-1");
    }
  }, [isGetProductError]);
  const saveLocalizedNames = (localizedArray: LocalizedArray) => {
    form.setFieldValue("nameLocalized", localizedArray);
    closeNameLocalizationDrawer();
  };

  const saveLocalizedDescriptions = (localizedArray: LocalizedArray) => {
    form.setFieldValue("descriptionLocalized", localizedArray);
    closeDescriptionLocalizationDrawer();
  };

  const isEditing = productId != "-1";

  const handleSubmit = async (values: z.infer<typeof validationSchema>) => {
    if (isEditing) {
      await putProduct({
        productId: Number(productId),
        body: values,
      });
      notifications.show({
        message: t`Produkt je bil posodobljen`,
        color: "green",
      });
      navigate("/dashboard/products");
      return;
    }
    await postProduct(values);
    notifications.show({
      message: t`Produkt je bil ustvarjen`,
      color: "green",
    });
    navigate("/dashboard/products");
  };

  const subPage: LimePageHeaderProps["subPage"] = {
    title: isEditing ? t`Uredi produkt` : t`Dodaj produkt`,
    onBackButtonClick: () => navigate("/dashboard/products"),

    rightSection: isEditing
      ? {
          options: [
            {
              label: t`Izbriši produkt`,
              color: Color.Error,
              icon: <VscTrash />,
              onClick: async () => {
                if (productId != null) {
                  alertModal({
                    title: t`Ali ste prepričani, da želite izbrisati produkt?`,
                    onConfirm: async () => {
                      await deleteProduct(Number(productId));

                      notifications.show({
                        message: t`Produkt je bil izbrisan`,
                        color: "green",
                      });

                      navigate("/dashboard/products");
                    },
                    isDanger: true,
                  });
                }
              },
            },
          ],
        }
      : undefined,
  };

  const defaultLanguageLocalizedNameIndex = form.values.nameLocalized.findIndex(
    (name) => name.language === defaultLanguage,
  );

  const defaultLanguageLocalizedDescriptionIndex =
    form.values.descriptionLocalized.findIndex(
      (desc) => desc.language === defaultLanguage,
    );

  const currencySymbol = returnCurrencySymbol({ currency: currency || "EUR" });

  return (
    <>
      <LimePageHeader title={""} subPage={subPage}></LimePageHeader>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box mt={"md"} maw={"600px"} mx={"auto"} pos={"relative"}>
          <div className="mt-4 flex flex-col gap-4 px-4 pb-6">
            <Text variant={TextVariant.BodyEmphasized} mt={"sm"} mb={"sm"}>
              <Trans>OSNOVNE INFORMACIJE</Trans>
            </Text>

            <LimeInput
              size="sm"
              label={t`Ime`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onPress={openNameLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `nameLocalized.${defaultLanguageLocalizedNameIndex}.value`,
              )}
            ></LimeInput>
            <LanguageSettingDrawer
              localized={form.values.nameLocalized}
              opened={nameLocalizationDrawerOpened}
              save={saveLocalizedNames}
              cancel={closeNameLocalizationDrawer}
              inputLabel={t`Ime`}
            ></LanguageSettingDrawer>

            <LimeTextarea
              size="sm"
              label={t`Opis`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onPress={openDescriptionLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `descriptionLocalized.${defaultLanguageLocalizedDescriptionIndex}.value`,
              )}
            ></LimeTextarea>
            <LanguageSettingDrawer
              localized={form.values.descriptionLocalized}
              opened={descriptionLocalizationDrawerOpened}
              save={saveLocalizedDescriptions}
              cancel={closeDescriptionLocalizationDrawer}
              inputLabel={t`Opis`}
            ></LanguageSettingDrawer>

            <LimeInput
              size="sm"
              label={t`Enota`}
              {...form.getInputProps(`unit`)}
            ></LimeInput>

            <Text variant={TextVariant.BodyEmphasized} mt={"sm"}>
              <Trans>KATEGORIJA</Trans>
            </Text>

            <ProductCategoryPicker
              selectedCategory={form.values.categoryId}
              setSelectedCategory={(id) => {
                if (form.values.categoryId === id) {
                  form.setFieldValue("categoryId", null);
                } else {
                  form.setFieldValue("categoryId", id);
                }
              }}
            ></ProductCategoryPicker>

            <Text variant={TextVariant.BodyEmphasized} mt={"sm"} mb={"sm"}>
              <Trans>CENA IN DAVEK</Trans>
            </Text>

            <LimeSingleSelect
              label={t`Tip`}
              items={[
                { label: t`Prodajni`, key: "SALES" },
                { label: t`Nabavni`, key: "PURCHASE" },
                { label: t`Nabavni in prodajni`, key: "ALL" },
              ]}
              value={form.values.type}
              onChange={(value: "SALES" | "PURCHASE" | "ALL") => {
                form.setValues({
                  type: value,
                });
              }}
            ></LimeSingleSelect>
            <Collapse
              in={form.values.type === "SALES" || form.values.type === "ALL"}
            >
              <TaxAndPrices
                title={t`PRODAJNI DAVEK`}
                netPrice={
                  form.values.type === "SALES" || form.values.type === "ALL"
                    ? form.values.salesNetPrice
                    : 0
                }
                grossPrice={
                  form.values.type === "SALES" || form.values.type === "ALL"
                    ? form.values.salesGrossPrice
                    : 0
                }
                setNetPrice={(value) =>
                  form.setFieldValue("salesNetPrice", value)
                }
                setGrossPrice={(value) =>
                  form.setFieldValue("salesGrossPrice", value)
                }
                tax={
                  form.values.type === "SALES" || form.values.type === "ALL"
                    ? form.values.salesTax
                    : 0
                }
                setTax={(value) => form.setFieldValue("salesTax", value)}
                currencySymbol={currencySymbol}
              ></TaxAndPrices>
            </Collapse>
            <Collapse
              in={form.values.type === "PURCHASE" || form.values.type === "ALL"}
            >
              <TaxAndPrices
                title={t`NABAVNI DAVEK`}
                netPrice={
                  form.values.type === "PURCHASE" || form.values.type === "ALL"
                    ? form.values.purchaseNetPrice
                    : 0
                }
                grossPrice={
                  form.values.type === "PURCHASE" || form.values.type === "ALL"
                    ? form.values.purchaseGrossPrice
                    : 0
                }
                setNetPrice={(value) =>
                  form.setFieldValue("purchaseNetPrice", value)
                }
                setGrossPrice={(value) =>
                  form.setFieldValue("purchaseGrossPrice", value)
                }
                tax={
                  form.values.type === "PURCHASE" || form.values.type === "ALL"
                    ? form.values.purchaseTax
                    : 0
                }
                setTax={(value) => form.setFieldValue("purchaseTax", value)}
                currencySymbol={currencySymbol}
              ></TaxAndPrices>
            </Collapse>

            <Divider />

            <LimeSwitch
              title={t`Vodenje zaloge`}
              {...form.getInputProps(`isQuantityManaged`, {
                type: "checkbox",
              })}
            />

            <Collapse in={form.values.isQuantityManaged}>
              <div className="flex flex-col gap-2">
                <LimeInput
                  label={t`SKU`}
                  size="sm"
                  {...form.getInputProps(`SKU`)}
                ></LimeInput>

                <LimeInput
                  label={t`EAN`}
                  size="sm"
                  {...form.getInputProps(`EAN`)}
                ></LimeInput>
              </div>
            </Collapse>
          </div>
          <div className="fixed bottom-0 left-0 z-10 w-full max-w-full border-t-1 border-divider bg-background px-4 py-4 md:left-auto md:max-w-[600px] md:px-8">
            <ErrorCard
              isCompact
              className="mb-2"
              message={putErrorMessage || postErrorMessage}
            />
            <Button className="w-full" type="submit" color="primary">
              Shrani
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};

const ProductCategoryPicker = ({
  selectedCategory,
  setSelectedCategory,
}: {
  selectedCategory: number | null;
  setSelectedCategory: (id: number | null) => void;
}) => {
  // APIS
  const { data: categories, refetch: refetchCategories } =
    api.product.useGetProductCategories({});

  const { mutateAsync: createCategory, processedErrorMessage } =
    api.product.usePostProductCategory();

  const { data: currency, isFetching: isFetchingCurrency } =
    api.values.useGetCurrency({});
  //

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const [
    nameLocalizationDrawerOpened,
    { open: openNameLocalizationDrawer, close: closeNameLocalizationDrawer },
  ] = useDisclosure(false);

  const [
    descriptionLocalizationDrawerOpened,
    {
      open: openDescriptionLocalizationDrawer,
      close: closeDescriptionLocalizationDrawer,
    },
  ] = useDisclosure(false);

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const formSchema = z.object({
    nameLocalized: z.array(
      z.object({
        language: z.enum(SUPPORTED_LANGUAGES),
        value: z.string(),
      }),
    ),
    descriptionLocalized: z.array(
      z.object({
        language: z.enum(SUPPORTED_LANGUAGES),
        value: z.string(),
      }),
    ),
    color: z.string().regex(/^#[0-9a-fA-F]{6}$/),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    initialValues: {
      nameLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      descriptionLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      color: "#E9B2BF",
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    form.validate();

    if (form.isValid()) {
      await createCategory(form.values);

      closeDrawer();

      refetchCategories();
    }
  };

  const saveLocalizedNames = (localizedArray: LocalizedArray) => {
    form.setFieldValue("nameLocalized", localizedArray);
    closeNameLocalizationDrawer();
  };

  const saveLocalizedDescriptions = (localizedArray: LocalizedArray) => {
    form.setFieldValue("descriptionLocalized", localizedArray);
    closeDescriptionLocalizationDrawer();
  };

  const defaultLanguageLocalizedNameIndex = form.values.nameLocalized.findIndex(
    (name) => name.language === defaultLanguage,
  );

  const defaultLanguageLocalizedDescriptionIndex =
    form.values.descriptionLocalized.findIndex(
      (desc) => desc.language === defaultLanguage,
    );

  const currencySymbol = returnCurrencySymbol({
    currency: currency || "EUR",
  });

  return (
    <div className="flex flex-col gap-2">
      <Radio.Group
        // label="Kategorija"
        value={selectedCategory?.toString()}
        onChange={(value) => setSelectedCategory(Number(value))}
      >
        <Stack gap={"xs"}>
          {categories?.map((category, i) => {
            const isSelected = selectedCategory === category.id;
            return (
              <Radio.Card
                value={category.id.toString()}
                key={category.id}
                withBorder={false}
              >
                <Group
                  className={cn(
                    "justify-start rounded-xl border-1 border-[#e4e4e7] px-4 py-2",
                    {
                      "border-[#202124] bg-[#20212410]": isSelected,
                    },
                  )}
                >
                  <div className="flex items-center justify-start gap-2">
                    <div
                      className={`h-5 w-5 rounded-full`}
                      style={{ backgroundColor: category.color }}
                    ></div>
                    <span className="text-sm">{category.name}</span>
                  </div>
                </Group>
              </Radio.Card>
            );
          })}
        </Stack>
      </Radio.Group>

      <Button variant="light" className="justify-start" onPress={openDrawer}>
        <div className="flex items-center justify-start gap-2">
          <div className="rounded-full bg-[#e4e4e7] p-1">
            <Plus className="" size={16}></Plus>
          </div>
          <span className="text-sm">
            <Trans>Ustvari kategorijo</Trans>
          </span>
        </div>
      </Button>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        position="bottom"
        styles={{
          content: {
            borderTopLeftRadius: "var(--mantine-radius-lg)",
            borderTopRightRadius: "var(--mantine-radius-lg)",
          },
          body: {
            paddingBottom: 0,
          },
        }}
        removeScrollProps={{
          allowPinchZoom: true,
        }}
        size={"450px"}
        overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
        title={t`Ustvari kategorijo`}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <LimeInput
              size="sm"
              label={t`Ime`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onPress={openNameLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `nameLocalized.${defaultLanguageLocalizedNameIndex}.value`,
              )}
            ></LimeInput>
            <LanguageSettingDrawer
              localized={form.values.nameLocalized}
              opened={nameLocalizationDrawerOpened}
              save={saveLocalizedNames}
              cancel={closeNameLocalizationDrawer}
              inputLabel={t`Ime`}
            ></LanguageSettingDrawer>

            <LimeTextarea
              size="sm"
              label={t`Opis`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onPress={openDescriptionLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `descriptionLocalized.${defaultLanguageLocalizedDescriptionIndex}.value`,
              )}
            ></LimeTextarea>
            <LanguageSettingDrawer
              localized={form.values.descriptionLocalized}
              opened={descriptionLocalizationDrawerOpened}
              save={saveLocalizedDescriptions}
              cancel={closeDescriptionLocalizationDrawer}
              inputLabel={t`Opis`}
            ></LanguageSettingDrawer>

            <LimeColorPicker
              {...form.getInputProps("color")}
              value={form.values.color}
              label={t`Barva`}
            ></LimeColorPicker>

            <ErrorCard message={processedErrorMessage}></ErrorCard>
            <Button className="w-full" type="submit" color="primary">
              Shrani
            </Button>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

const TaxAndPrices = ({
  grossPrice,
  netPrice,
  setGrossPrice,
  setNetPrice,
  title,
  tax,
  setTax,
  currencySymbol,
}: {
  title: string;
  tax: number;
  setTax: (value: number) => void;
  netPrice: number;
  grossPrice: number;
  setNetPrice: (value: number) => void;
  setGrossPrice: (value: number) => void;
  currencySymbol?: string;
}) => {
  const [manualChanged, setManualChanged] = useState<"net" | "gross">("net");

  return (
    <div className="flex flex-col gap-2">
      <Text variant={TextVariant.BodyEmphasized} mt={"sm"} mb={"sm"}>
        <Trans>{title}</Trans>
      </Text>

      <Tabs
        fullWidth
        selectedKey={tax.toString()}
        onSelectionChange={(key) => {
          setTax(Number(key));

          if (manualChanged === "net") {
            setGrossPrice(
              Math.round(netPrice * (1 + Number(key) / 100) * 100) / 100,
            );
          } else if (manualChanged === "gross") {
            setNetPrice(
              Math.round((grossPrice / (1 + Number(key) / 100)) * 100) / 100,
            );
          }
        }}
      >
        <Tab key="0" title="brez"></Tab>
        <Tab key="5" title="5%"></Tab>
        <Tab key="9.5" title="9.5%"></Tab>
        <Tab key="22" title="22%"></Tab>
      </Tabs>

      <div className="flex gap-2">
        <LimeInput
          size="sm"
          label={t`Cena brez davka`}
          type="number"
          value={netPrice.toString()}
          endContent={currencySymbol ? currencySymbol : <Spinner size="sm" />}
          onValueChange={(value) => {
            const newNetValue = Number(value);
            const newGrossValue = newNetValue * (1 + tax / 100);

            setNetPrice(newNetValue);
            setGrossPrice(Math.round(newGrossValue * 100) / 100);
            setManualChanged("net");
          }}
        ></LimeInput>
        <LimeInput
          size="sm"
          label={t`Cena z davkom`}
          endContent={currencySymbol ? currencySymbol : <Spinner size="sm" />}
          value={grossPrice.toString()}
          type="number"
          onValueChange={(value) => {
            const newGrossValue = Number(value);
            const newNetValue = newGrossValue / (1 + tax / 100);

            setGrossPrice(newGrossValue);
            setNetPrice(Math.round(newNetValue * 100) / 100);
            setManualChanged("gross");
          }}
        ></LimeInput>
      </div>
    </div>
  );
};
