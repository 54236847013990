import {
  PostGiftCard,
  PutGiftCard,
  DeleteGiftCard,
  GetGiftCards,
  GetGiftCardById,
  GetUniqueGiftCardCode,
  GetVerifyCode,
  GetGiftCardByCode,
} from "@/server-types";
import { post, get, put, del } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";
import { keepPreviousData } from "@tanstack/react-query";

export const usePostGiftCard = () => {
  return useCustomMutation({
    mutationFn: (body: PostGiftCard["body"]) =>
      post<PostGiftCard>("/gift-card", body),
  });
};

export const usePutGiftCard = () => {
  return useCustomMutation({
    mutationFn: ({ id, body }: { id: number; body: PutGiftCard["body"] }) =>
      put<PutGiftCard>(`/gift-card/id/${id}`, body),
  });
};

export const useDeleteGiftCard = () => {
  return useCustomMutation({
    mutationFn: ({ id }: { id: number }) =>
      del<DeleteGiftCard>(`/gift-card/id/${id}`),
  });
};

export const useGetGiftCards = (
  query: GetGiftCards["query"] & {
    disabled?: boolean;
  },
) => {
  return useCustomQuery({
    queryKey: ["giftCards", query],
    queryFn: () => get<GetGiftCards>("/gift-card", query),
    placeholderData: keepPreviousData,
    enabled: !query.disabled,
  });
};

export const useGetGiftCard = (id?: number) => {
  return useCustomQuery({
    queryKey: ["giftCard", id],
    queryFn: () => get<GetGiftCardById>(`/gift-card/id/${id!}`),
    enabled: id != null && id > -1,
  });
};

export const useGetUniqueGiftCardCode = (isDisabled?: boolean) => {
  return useCustomQuery({
    queryKey: ["giftCardCode"],
    queryFn: () =>
      get<GetUniqueGiftCardCode>("/gift-card/unique-code/generate"),
    enabled: !isDisabled,
    refetchOnWindowFocus: false,
  });
};

export const useGetVerifyCode = (code: string, isDisabled?: boolean) => {
  return useCustomQuery({
    queryKey: ["verifyGiftCardCode", code],
    queryFn: () => get<GetVerifyCode>(`/gift-card/unique-code/verify/${code}`),
    enabled: code.length >= 3 && !isDisabled,
  });
};

export const useGetGiftCardDetailsByCode = (code: string) => {
  return useCustomQuery({
    queryKey: ["giftCardDetailsByCode", code],
    queryFn: () => get<GetGiftCardByCode>(`/gift-card/code/${code}`),
    enabled: code.length >= 3,
  });
};
